<template>
  <v-container>
    <v-toolbar flat dark style="background-color:transparent!important; text-align: center; margin-top:-75px">
    </v-toolbar>
    <v-card flat>
      <v-card-text class="p-0 card-backround">
        <template>
          <v-form>
            <v-container>
              <v-row>
                <v-col sm="12" lg="12" md="12">
                  <template>
                    <HeaderResultBetting @child-checkbox="dateWinLoseFilterValue" />
                    <v-data-table style="font-family: 'khmer mef1'" class="elevation-1" item-key="_id"
                      mobile-breakpoint=NaN :headers="headersReportBetting" :footer-props="footerProps"
                      :page.sync="dataRB.page" :items="dataReportBetting.reports"
                      :items-per-page.sync="dataRB.rowsPerPage" :server-items-length="dataReportBetting.total_rows"
                      :loading="getLoading" @update:options="reportBetting" @update:page="reportBetting"
                      @update:items-per-page="reportBetting" @update:sort-desc="reportBetting">
                      <template v-slot:[`header.amount`]="{ header }">
                        {{ header.text }} ({{ getUserCurrency() }})
                      </template>
                      <template v-slot:[`header.amount_win`]="{ header }">
                        {{ header.text }} ({{ getUserCurrency() }})
                      </template>

                      <template v-slot:[`item.is_win`]="{ item }">
                        <v-chip label :color="item.is_win ? 'cir-blue' : 'red'"
                          style="padding:10px;color:#ffffff; font-size:12px;height:22px">
                          <span class="text-white">
                            {{ item.is_win ? "Win" : "Lose" }}
                          </span>
                        </v-chip>
                      </template>
                      <template v-slot:[`item.date`]="{ item }">
                        {{ convertDateTime(item.date) }}
                      </template>
                      <template v-slot:[`item.bet_name`]="{ item }">
                        <v-chip label :class="item.bet_color"
                          style="padding:10px;color:#ffffff; font-size:12px;height:22px">
                          <span class="white--text">{{ item.bet_name }}</span>
                        </v-chip>
                      </template>
                      <template v-slot:[`item.amount`]="{ item }">
                        {{ currencyFormat(item.amount) }}
                      </template>
                      <template v-slot:[`item.bet_payout`]="{ item }">
                        {{ currencyFormat(item.bet_payout) }}
                      </template>
                      <template v-slot:[`item.cast`]="{ item }">
                        <span v-if="item.cast == null" class="c-blue">{{ currencyFormat(item.amount) }} </span>
                        <span v-else class="c-blue">
                          {{ currencyFormat(item.cast) }}
                        </span>
                      </template>
                      <template v-slot:[`item.amount_win`]="{ item }">
                        <span v-if="item.amount_win == null || item.amount_win == 0" class="c-blue">0</span>
                        <span v-if="item.amount_win > 0" class="c-blue">
                          {{ currencyFormat(item.amount_win) }}
                        </span>
                        <span v-if="item.amount_win < 0" class="c-red">
                          {{ currencyFormat(item.amount_win) }}
                        </span>
                      </template>
                      <template v-slot:[`item.result_name`]="{ item }">
                        <v-chip label :class="item.result_color"
                          style="padding:10px;color:#ffffff; font-size:12px;height:22px">
                          <span class="white--text">{{ item.result_name }}</span>
                        </v-chip>
                      </template>
                      <template v-slot:[`item.print`]="{ item }">
                        <print-report-betting :bettingReport="item" />
                      </template>
                    </v-data-table>
                  </template>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </template>
      </v-card-text>
    </v-card>
    <nav class="fab-container mb-5 ">
      <v-menu top offsets>
        <template v-slot:activator="{ on, attrs }">
          <v-btn text v-bind="attrs" v-on="on" class="px-0">
            <a class="buttons main-fab animated infinite pulse delay-2s flex-v-center" href="#">
              <svg width="35px" height="35px" style="margin-left:5px" viewBox="-1.6 -1.6 19.20 19.20"
                xmlns="http://www.w3.org/2000/svg" fill="#C55180" stroke="#C55180" stroke-width="1.6"
                transform="matrix(1, 0, 0, 1, 0, 0)">
                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                <g id="SVGRepo_iconCarrier">
                  <path
                    d="m 3 0 c -1.660156 0 -3 1.339844 -3 3 v 7 c 0 1.660156 1.339844 3 3 3 h 10 c 1.660156 0 3 -1.339844 3 -3 v -7 c 0 -1.660156 -1.339844 -3 -3 -3 z m 0 2 h 10 c 0.554688 0 1 0.445312 1 1 v 7 c 0 0.554688 -0.445312 1 -1 1 h -10 c -0.554688 0 -1 -0.445312 -1 -1 v -7 c 0 -0.554688 0.445312 -1 1 -1 z m 2 12 c -1.105469 0 -2 0.894531 -2 2 h 10 c 0 -1.105469 -0.894531 -2 -2 -2 z m 0 0"
                    fill="#2e3436"></path>
                </g>
              </svg>

            </a>
            <div style="position: absolute; font-size: 14px; color:white; font-weight: 700;">{{ channelType }}
            </div>
          </v-btn>

        </template>
        <v-list>
          <v-list-item v-for="(item, index) in newChannelList" :key="index">
            <v-list-item-title style="position:relative" class="channel-item">
              <div @click="_switchChannel(item)">
                <svg viewBox="-1.6 -1.6 19.20 19.20" xmlns="http://www.w3.org/2000/svg" fill="#C55180" stroke="#C55180"
                  stroke-width="1.6" transform="matrix(1, 0, 0, 1, 0, 0)">
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g id="SVGRepo_iconCarrier">
                    <path
                      d="m 3 0 c -1.660156 0 -3 1.339844 -3 3 v 7 c 0 1.660156 1.339844 3 3 3 h 10 c 1.660156 0 3 -1.339844 3 -3 v -7 c 0 -1.660156 -1.339844 -3 -3 -3 z m 0 2 h 10 c 0.554688 0 1 0.445312 1 1 v 7 c 0 0.554688 -0.445312 1 -1 1 h -10 c -0.554688 0 -1 -0.445312 -1 -1 v -7 c 0 -0.554688 0.445312 -1 1 -1 z m 2 12 c -1.105469 0 -2 0.894531 -2 2 h 10 c 0 -1.105469 -0.894531 -2 -2 -2 z m 0 0"
                      fill="#2e3436"></path>
                  </g>
                </svg>
                <div style="position:absolute; top:5px; left: 15px; font-weight:700">{{ item }}</div>
              </div>
            </v-list-item-title>

          </v-list-item>
        </v-list>
      </v-menu>
    </nav>
  </v-container>
</template>


<script>
import headersReportBetting from "./_api/columnsReportBetting";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import PrintReportBetting from './components/PrintReportBetting.vue';

export default {
  components: {
    HeaderResultBetting: () => import("./components/HeaderResultBetting.vue"),
    PrintReportBetting,
  },
  data() {
    return {
      newChannelList: [],
      headersReportBetting: headersReportBetting,
      footerProps: {
        'items-per-page-options': [10, 20, 50, 100, 200]
      },
      channelType: 1,
      loading: false,
      dialog: false,
      dataRB: {
        page: 1,
        rowsPerPage: 10
      },
      dateFilterRB: {

      },
    }
  },
  item: {
    type: Object,
    default: null,
  },

  computed: {
    ...mapGetters("$_betting", {
      dataReportBetting: 'getReportBetting'
    }),
    ...mapGetters("$_betting", ["getLoading"]),
    ...mapGetters("$_modules", {
      userInfor: "getUserInfo",
    }),
    ...mapState("$_modules", {
      channelId: "channelId",
    }),
  },
  mounted() {
    this.passChannelList = this.$cookies.get("sbc_user").channel_list;
    this.channelType = this.$cookies.get('sbc_channel_type') ? this.$cookies.get('sbc_channel_type') : 1
    this.getdota(this.channelType)
  },
  watch: {
    channelId: function (newVal, oldVal) {
      // console.log(`channelId new: ${newVal} old: ${oldVal}`)
      if (newVal != oldVal) {
        this.channelType = newVal
        this.reportBetting()
        this.getdota(this.channelType)
      }
    },
  },
  methods: {
    dateWinLoseFilterValue: function (params) {
      this.dateFilterRB = params;
      this.dataRB = {
        page: 1,
        rowsPerPage: 10
      }
      this.reportBetting();
    },
    reset() {
      this.$refs.form.reset();
    },

    currencyFormat(num) {
      num = parseFloat(num)
      return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    },

    getUserCurrency() {
      if (this.userInfor.currency_type === 1) {
        return "$"
      } else if (this.userInfor.currency_type === 2) {
        return "៛"
      } else if (this.userInfor.currency_type === 3) {
        return "฿"
      }
    },

    async getUserInfo() {
      await this.fetchUserInfo();
    },

    async reportBetting() {
      let body = {};
      if (this.dateFilterRB.startDate != null && this.dateFilterRB.endDate != null) {
        body = {
          rows_per_page: this.dataRB.rowsPerPage,
          page: this.dataRB.page,
          start_date: this.dateFilterRB.startDate,
          end_date: this.dateFilterRB.endDate
        }
      } else {
        body = {
          rows_per_page: this.dataRB.rowsPerPage,
          page: this.dataRB.page
        }
      }
      await this.fetchReportBetting(body)
    },

    _switchChannel(selectedChannel) {
        // console.log(`this.channelSelected >> ${channelType} ::::: ${this.channelSelected}`)
        if (this.channelType != selectedChannel) {
        // console.log(`this.channelSelected != ${channelType} ::::: ${this.channelSelected}`)
        this.channelType = selectedChannel;
        this.$cookies.set("sbc_channel_type", selectedChannel);
        this.reportBetting();
        this.getdota(selectedChannel)
      }
    },

    getdota(channelType){
      this.newChannelList = []
      for(var i = 0; i < this.passChannelList.length; i++) {
            if(this.passChannelList[i] != channelType) {
                this.newChannelList.push(this.passChannelList[i]);
            }
        }
    },

    ...mapActions("$_myinfo", [
      "fetchMyInfo",
    ]),

    ...mapMutations("$_betting", ["REPORT_BETTING"]),
    ...mapActions("$_betting", ["fetchReportBetting"]),
  }

}
</script>

<style scoped>

.c-cancel {
  color: grey;
}
/* width */
::-webkit-scrollbar {
  width: 5px
}
/* @media (min-width: 300px){
  .container {
    max-width: 90vw;
    margin:auto!important;
    background: transparent!important;
  }
}
@media (min-width: 1264px){
.container {
    max-width: 90vw;
    margin:auto!important;
    background: transparent!important;
  }
} */

</style>