<template>
    <v-container class="bg-surface-variant" >
        <v-row no-gutters class="lotto-border">
          <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4" xxl="4" class="lotto-border yuki-order-1" style="overflow: auto; ">
            <LeftSection/>
          </v-col>
          <v-col cols="12" xs="12" sm="12" md="8" lg="8" xl="8" xxl="8" class="lotto-border yuki-order-2" style="overflow: auto; ">
            <GameContent  :passClientResult="clientResult"
                          :passClientRandom="clientRandom"
                          :passRandomNumber="randomNumber"
                          :passClientTime="clientTime">
            </GameContent>
            <GameResult/>
          </v-col>
        </v-row>
    </v-container>
</template>

<script>
import LeftSection from "./components/leftSection.vue";
import GameContent from "./components/GameContent.vue";
import GameResult from "./components/GameResult.vue";

import {  mapState, mapGetters, mapMutations, mapActions  } from "vuex";
export default {
  components: {
    LeftSection,
    GameContent,
    GameResult,
  },
  data() {
    return {
      message : "Hello world",
      orderResult : {},
      orderResultUnderOver:{},
      clientTime: {
        is_countdown: null,
        is_open_betting: null,
        time: null,
      },
      clientRandom: {
                  "randmon_time": null,
                  "is_open": false
              },
      clientResult: null,
      randomNumber :0
    }
  },
  watch: {
    channelId: async function (newVal, oldVal) {
      if (newVal != oldVal) {
        this.channelList = this.$cookies.get("sbc_user").channel_list;
        this.$socket.removeAllListeners()
        this.$socket.disconnect()
        this.connectedSocket();
      }
    }
  },
  computed: {
    ...mapGetters("$_modules", {
      channelId:"channelId",
      isSound: "getIsSound"
    }),
    ...mapGetters("$_machine_yuki", {
      lastTwentyResults: "getLastTwentyResult",
      TypeMaster: "getTypeMaster"
    }),
    ...mapState("$_modules", {
      channelId: "channelId",
    }),
  },
  created() {
    this.connectedSocket();
    this._initialData();
  },
  methods:{
    connectedSocket() {
      this.$connectSocket();
      this.listenerSocket()
    },
    _initialData() {
      this.fetchUserInfo();
      this.fetchLastResults();
      this.fetchCurrentBetting();
      this.fetchCurrentGame();
      this.fetchPreviousBetting();
      this.fetchTypeMaster();
      this.fetchButton();
    },
    listenerSocket(){
       // socket get random number, total number,
      this.$socket.on("client-random", (data) => {
        // console.log(`socket on client-random: ${JSON.stringify(data)}`);
        if (data && data.randmon_time != null) {
                        let interval;
                        const _this = this;
                        this.clientRandom = data;
                        this.clientTime = null;
                        interval = setInterval(function () {
                            let result = parseInt(Math.random() * (12 - 1) + 1);
                            _this.randomNumber = result;
                        }, 100);
                            setTimeout(() => {
                                clearInterval(interval);
                            }, 1300);
                    }
      });
      // socket get time,  is betting, is coldown
      this.$socket.on("client-time", (data) => {
        // console.log(`socket on client-time: ${JSON.stringify(data)}`);
        if (data) {
          // define client time to display count down time at play zone
          this.clientTime = data;
          // console.log(this.clientTime)
          // play audio sound
          if (data.time == 59) {
            this._playSoundBetDown();
          }
          if (data.time < 11 && data.time !== 0) this._playSoundTing();
          if (data.time == 0) this._playSoundGoodLuck();
        }
      });
      // get new game
      this.$socket.on("client-new-game", (data) => {
        // console.log(`socket on client-newgame: ${JSON.stringify(data)}`);
        if (data) {
          this.clientRandom = [];
          this.bonus = [];
          // this._clearResult();
          // this.randomNumber = null;
          // this.$confetti.stop();
          this.UPDATE_TOTAL_INVOICE(0);
          this._initialData();
        }
      });
      // get game result and bonus
      this.$socket.on("client-result", (data) => {
        // console.log(`socket on client-result: ${JSON.stringify(data)}`);
//         {
//   "_id": "6463b66e39178ba6a8ccddfa",
//   "bonus_between_year": {
//     "is_bonus": false,
//     "year_number": 18,
//     "payout": 0
//   },
//   "between_year": {
//     "year_name_kh": "កុរ",
//     "year_name_vn": "Lợn",
//     "year_name_tb": "กุน",
//     "year_name_en": "Pig",
//     "year_number": 12
//   },
//   "small_large": {
//     "year_name_kh": "ក្រាស់",
//     "year_name_vn": "Đặc",
//     "year_name_tb": "หนา",
//     "year_name_en": "Thick",
//     "year_number": 14
//   },
//   "range": {
//     "year_name_kh": "10-11-12",
//     "year_name_vn": "10-11-12",
//     "year_name_tb": "10-11-12",
//     "year_name_en": "10-11-12",
//     "year_number": 18
//   },
//   "blue_red": {
//     "year_name_kh": "ក្រហម",
//     "year_name_vn": "đỏ",
//     "year_name_tb": "สีแดง",
//     "year_name_en": "red",
//     "year_number": 19
//   },
//   "odd_even": {
//     "year_name_kh": "គូ",
//     "year_name_vn": "Đôi",
//     "year_name_tb": "คู่",
//     "year_name_en": "even",
//     "year_number": 22
//   },
//   "no": 40175
// }
        if (data) {
          this.clientResult = data;
        }
      });
      this.$socket.on("client-bonus", (data) => {
        // console.log(`socket on client-bonus: ${JSON.stringify(data)}`);
        // {"is_bonus":false,"year_number":18,"payout":0}
        if (data) {
          this.bonus = data;
          if (this.bonus.is_bonus) {
            this.$confetti.start();
            this._playSoundCongratulation();
          }
        }
      });
      this.$socket.on("client-close", (data) => {
        // console.log(`socket on client-close: ${JSON.stringify(data)}`);
        // {"is_close":true}
        if (data) {
          this.is_close = data;
        }
      });
      // this.$socket.on("client-reset", (data) => {
        // console.log(`socket on client-reset: ${JSON.stringify(data)}`);
        // if (data) {

        // }
      // });
      this.$socket.on("client-update-user-info", (data) => {
        // console.log(`socket on client-update-user-info: ${JSON.stringify(data)}`);
        if (data) {
          if (data.is_suspend) {
            this.$toastr.e(`Your account have been blooked!`)
            this.onLogout()
          }
          this.updateUserInfo(data);
        }
      });
    },
    onLogout() {
      this.$socket.removeAllListeners()
      this.$socket.disconnect()
      this.$cookies.remove('sbc_channel_type')
      this.$cookies.remove('sbc_token')
      this.$router.push("/login")
    },
    _clearResult() {
              new Promise((resolve) => setTimeout(resolve, 5000));
              this.clientResult = null;
          },
    _playSoundBang() {
      if(this.isSound){
        let audio = new Audio("/sound/bangbang.mp3");
        let promise = audio.play();
        if (promise) {
          promise.catch(function () {});
        }
      }
    },
    _playSoundBetDown() {
      if(this.isSound){
        let audio = new Audio("/sound/betdown_please.mp3");
        let promise = audio.play();
        if (promise) {
          promise.catch(function () {});
        }
      }
    },
    _playSoundTing() {
      if(this.isSound){
        let audio = new Audio("/sound/ting1s.mp3");
        let promise = audio.play();
        if (promise) {
          promise.catch(function () {});
        }
      }
    },
    _playSoundGoodLuck() {
      if(this.isSound){
        let audio = new Audio("/sound/good_luck.mp3");
        let promise = audio.play();
        if (promise) {
          promise.catch(function () {});
        }
      }
    },
    _playSoundCongratulation() {
      if(this.isSound){
        let audio = new Audio("/sound/congratulations.mp3");
        let promise = audio.play();
        if (promise) {
          promise.catch(function () {});
        }
      }
    },
    // ...mapMutations("$_machine", [
    //   "LATEST_FIVE_RESULTS",
    //   "CURRENT_BETTING",
    //   "PREVIOUS_BETTING",
    //   "COUNT_TIME",
    // ]),
    // ...mapActions("$_machine", [
    //   "fetchLastResults",
    //   "fetchCurrentBetting",
    //   "fetchPreviousBetting",
    //   "fetchCurrentGame",
    //   "SocketInAction",
    //   "fetchButton",
    // ]),
    ...mapMutations("$_machine_yuki", [
      "LATEST_FIVE_RESULTS",
      "CURRENT_BETTING",
      "PREVIOUS_BETTING",
      "COUNT_TIME",
      "UPDATE_TYPE_MASTER",
      "UPDATE_TOTAL_INVOICE"
    ]),
    ...mapActions("$_machine_yuki", [
      "fetchLastResults",
      "fetchCurrentBetting",
      "fetchPreviousBetting",
      "fetchCurrentGame",
      "SocketInAction",
      "fetchButton",
      "fetchTypeMaster"
    ]),
    ...mapActions("$_modules", [
      "fetchUserInfo",
      "updateUserInfo"
    ]),
  },  
  name: 'IndexLotto',
  async beforeRouteLeave(to, from, next) {
    this.$socket.removeAllListeners();
    this.$socket.disconnect();
    next();
  }
}
</script>

<style>

/*LEFT*/
.bg-surface-variant{  padding: 0 !important; margin-top: 1px;}
.lotto-nav-head{  background-color: #0c478b;   height: 50px;  overflow: hidden ; margin-top:0px}
.lotto-nav-head .span-title{ font-size: 1.25rem;  display: block;  color: white;  padding: 14px 14px 0px 12px;}
.lotto-nav-head .span-btn{width:15%; text-align: center;   font-size: 1.25rem;  display: inline-block;  color: white;  font-weight: 900;  padding: 14px 14px 12px 12px;}
.lotto-nav-head .span-active{  background-color: #14214b;}


/*left content*/
.list-result-head ul{  list-style: none;  padding: 0 !important;}
.list-result-head ul li{padding-left: 6px !important; padding-top: 3px;padding-bottom: 3px;font-size: 1rem; color: #0c478b;}
.lotto-border{border: 2px solid #0c478b;border-top: 0; background-color: white;}
.title-current{background-color: #0c478b;color: white;padding: 8px 8px 12px 8px;font-size: 1.2rem;}
.list-result-body ul{list-style: none;padding: 0;padding-left: 8px !important;}
.list-result-body ul li{padding-top: 7px;
  padding-bottom: 3px;
  font-size: .75rem;
  color: #111416;
  height: 22px;}
.list-head li{color: #0c478b !important;font-size: .95rem !important;padding-top: 3px;padding-bottom: 3px;}
.list-head li .item-right{float: right;padding-right: 12px;}
.list-result-body ul li .row-item{ float: right;}
.list-result-body ul li .row-item .col-item-3:first-child{padding-left: 10px; padding-right: 40px;}
.list-result-body ul li .row-item .col-item-3{padding-left: 20px;}
.list-result-body ul li .row-item .col-item-3:last-child{padding-right: 20px;}

/*for yuki*/

/*TOP*/
.yuki-padding-6{padding: 6px 6px 6px 6px !important;}
.yuki-left-top{border: 4px solid #0c478b;}
.yuki-left-top .yk-l-top{background-color: #cc962a; text-align: center; padding: 0 !important; margin: 0 !important;}
.yuki-left-top .yk-l-top ul{list-style: none; padding: 0;}
.yuki-left-top .yk-l-top ul li:nth-child(1){font-size: 1.25rem;padding-top: 12px; padding-bottom: 8px;}
.yuki-left-top .yk-l-top ul li:nth-child(2){font-size: 1.25rem; font-weight: 800;padding-bottom: 10px;}
.yuki-left-top .yk-l-middle{background-color: #0c478b;text-align: center; padding: 6px 0px 6px 0px !important; margin: -16px 0px 0px 0px !important;}
.yuki-left-top .yk-l-middle span{font-size: 1.2rem; font-weight: 600;}
.yuki-left-top .yk-l-bottom{text-align: center; padding: 13px 0px 12px 0px; }
.yuki-left-top .yk-l-bottom span{color: #333; font-size: 3.2rem; font-weight: 600;}


.yuki-middle-top{background-color: white;width: 100%;height: 100%; position: relative;}
.yuki-middle-top img{width: 100%;height: auto;}

.yk-r-bottom{
  padding: 36px 0px 32px 0px;
}


.yuki-middle-top-img-bottom-box{width: 100px; height: 100px; position: absolute; left: 40%; bottom: 16%;}
.yuki-middle-border-left{ width: 3px; height: 95%; background-color: #bf811c; position: absolute; top: 2%; left: 6px;}
.yuki-middle-border-right{width: 3px; height: 95%; background-color: #bf811c; position: absolute; top: 2%; right: 6px;}
.yuki-middle-border-bottom{width: auto; height: 3px; background-color: #bf811c; position: absolute; bottom: 3%;left: 6px;right: 6px;}

.yuki-right-top .yk-r-bet-limit{background-color: #0c478b;text-align: center; padding: 6px 0px 6px 0px !important; margin: 0px 0px 0px 0px !important;}
.yuki-right-top .yk-r-bet-limit span{font-size: 1.2rem; font-weight: 600;}
.yuki-right-top .yk-r-top ul{ padding: 0 !important; list-style: none;}
.yuki-right-top .yk-r-top ul li{color: #1e1e1e; font-size: 1rem; font-weight: 700; padding-left: 6px;}
.yuki-right-top .yk-r-top ul li span{color: rgb(187, 52, 52); font-weight: 800; font-size: 1.1rem;}
.yuki-right-top .yk-r-middle{background-color: #cc962a; text-align: center; padding: 6px 0px 6px 0px !important; margin: -16px 0px 0px 0px !important;}
.yuki-right-top .yk-r-middle span{ font-size: 1.2rem; font-weight: 600; }
.yuki-right-top .yk-r-bottom{text-align: center;}
.yuki-right-top .yk-r-bottom span{color: #333; font-size: 1.2rem; font-weight: 600;}

.yuki-right-top{border: 4px solid #0c478b;}

.yk-r-top-balance{padding-left: 6px; }
.yk-r-top-balance span{font-size: 1rem;}

/*YEAR*/
.yuki-year-box{display: grid;grid-template-columns: repeat(6,1fr);grid-row: minmax(50px, auto); padding-bottom: 0px !important;}
.yuki-year-item{ padding: 2px;}
.yuki-year-item:hover{cursor: pointer;}
/*
.yuki-year-item-border-blue{border: 2px solid #0c478b;}
.yuki-year-item-border-red{border: 2px solid red;}
*/
.yuki-year-item img{width: 100%;height: auto;}


.yuki-odd-even-box{display: grid;grid-template-columns: repeat(4,1fr);grid-row: minmax(50px, auto); padding-top: 0px !important;}
.yuki-odd-even-item{text-align: center; color: white; font-size: 1.75rem; font-weight: 600; padding:0px 2px 2px 0px;}
.yuki-odd-even-item:nth-child(4){padding-right: 0px;}
.yuki-odd-even-item:nth-child(8){padding-right: 0px;}
.yk-color-blue{background-color: #0000ff; padding: 6px 0px 6px 0px;}
.yk-color-even{background-color: #027889; padding: 6px 0px 6px 0px;}
.yk-color-red{background-color: #ff0000; padding: 6px 0px 6px 0px;}
.yk-color-odd{background-color: #cc962a; padding: 6px 0px 6px 0px;}

.yuki-input-bet{display: grid;grid-template-columns: repeat(4,1fr);grid-row: minmax(50px, auto); padding-top: 0px !important; min-width: 100%;}
.yk-bet-input-number{grid-column: 1/4; position: relative;}
.yk-bet-input-number input{width: 100%; height: 48px; font-size: 1.5rem;font-weight: 900; padding: 6px 56px 6px 16px !important; border: 2px solid #0c478b;}
.yk-bet-input-number span{ position: absolute; top: 0; right: 0; width: 56px; height: 48px; border: 2px solid #0c478b;text-align: center;}
.yk-bet-input-number span .v-icon.v-icon{line-height: 40px; font-size: 2rem; font-weight: 900; color: red;}
.yk-bet-input-number span:hover{cursor: pointer;}
.yk-bet-repet-btn{padding: 0px 0px 0px 6px;}
.yk-bet-repet-btn-content{background: linear-gradient(180deg, #198eff 0, #b6dcff 51%, #1a73ff); height: 100%; text-align: center; border-radius: 3px;}
.yk-bet-repet-btn-content span{font-size: 1.2rem; font-weight: 900; line-height: 50px; color: #333;}
.yk-bet-repet-btn-content:hover{cursor: pointer;}
.yk-bet-bet-btn{padding: 0px 0px 0px 6px;}
.yk-bet-bet-btn-content{background: linear-gradient(180deg, #c68615 0, #fad488 51%, #c7891a); height: 100%; text-align: center; border-radius: 3px;}
.yk-bet-bet-btn-content span{font-size: 1.2rem; font-weight: 900; line-height: 50px; color: #333;}
.yk-bet-bet-btn-content:hover{cursor: pointer;}


/*yuki result*/
.yuki-res-padding-6{padding: 0px 6px 6px 6px !important;}
.yuki-res-head{display: grid;grid-template-columns: repeat(5,1fr);grid-row: minmax(50px, auto); padding-top: 0px !important; min-width: 100%; border-top: 2px solid #0a295a; border-radius: 3px 3px 0px 0px;}
.yuki-res-head-item{background-color: #174e87; padding-top: 8px; padding-bottom: 8px; text-align: center; }
.yuki-r-h-active{background-color: #0a295a;}
.yuki-res-head-item a{color: white !important; font-size: 1rem; font-weight: 600;}

.yuki-res-body{min-height: 46px; border: 2px solid #0a295a;}


.yuki-res-body-row{display: block; width: 100%; height: auto; background-color: #027889;}
.yuki-res-body-col{min-width: 100%; min-height: 46px; overflow: hidden; }
.yuki-res-body-row:nth-child(odd) { background-color: #e6f1fd;}
.yuki-res-body-row:nth-child(even) {background-color: #ffffff;}
.yuki-res-body-col-item img{ width: 100%;height: auto;}
.yuki-res-body-col-item{width: 86px; height: auto; float: left; margin-left: 6px; padding:3px 0px 3px 0px;}

.blink {
  animation: blinking 1s steps(5, start) infinite;
  -webkit-animation: blinking 1s steps(5, start) infinite;
  animation: blinking 1s infinite;
}


@keyframes blinking {
  0% {
    box-shadow: 2px 2px 2px 2px transparent inset;
  }

  50% {
    /* background: #A14E4A;
    outline: 5px solid #90802F; */
    box-shadow: 0px 0px 0px 0px #A14E4A inset;
    background: pink;
  }

  100% {
    box-shadow: 2px 2px 2px 2px transparent inset;
  }
}
.l-number-active {
  background: #bc1e2d;
  color: #fff;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top:-5px;
}
.number {
  float: left;
  text-align: center;
  height: 37px;
}
.focus-one-result {
  background-image: url("/images/red_btn.png");
  background-repeat: no-repeat;
  background-size: 45px 45px;
  background-position: center;
}
.l-logo-result-random-result {
  text-align: center;
  margin-top: 0px;
  line-height: 50px;
  color: #fff !important;
  font-weight: 700;
  background: linear-gradient(180deg, #fff700 0, #f70);
  color: #000;
  font-size: 27px;
  font-weight: 700;
  position: relative;
}



.v-tabs:not(.v-tabs--vertical).v-tabs--right > .v-slide-group--is-overflowing.v-tabs-bar--is-mobile:not(.v-slide-group--has-affixes) .v-slide-group__next, .v-tabs:not(.v-tabs--vertical):not(.v-tabs--right) > .v-slide-group--is-overflowing.v-tabs-bar--is-mobile:not(.v-slide-group--has-affixes) .v-slide-group__prev {
  display: none !important;
  visibility: hidden;
}

@media screen and (min-width: 1904px){
 /*left*/
 .list-result-head ul li{padding-left: 12px; padding-top: 12px;padding-bottom: 12px;font-size: 1.2rem;}
  .title-current{padding: 12px 12px 12px 12px;font-size: 1.2rem;}
  .list-result-body ul{padding-left: 10px;}
  .list-result-body ul li{padding-top: 6px;padding-bottom: 6px;font-size: .85rem;}
  .list-head li{color: #0c478b;font-size: .95rem !important;padding-top: 6px;padding-bottom: 6px;}


  /*yuki*/
  .yuki-middle-top-img-bottom-box{width: 100px; height: 100px; position: absolute; left: 40%; bottom: 10%;}
  
}

/*  1263 959*/
@media screen and (max-width: 1263px){
  /*left*/
  .list-result-head ul li{padding-left: 8px; padding-top: 8px;padding-bottom: 8px;font-size: .86rem;}
  .title-current{padding: 6px 6px 6px 6px;font-size: .90rem;}
  .list-result-body ul{padding-left: 6px;}
  .list-result-body ul li{padding-top: 3px;padding-bottom: 3px;font-size: .65rem;}
  .list-head li{color: #0c478b;font-size: .85rem !important;padding-top: 6px;padding-bottom: 6px;}
  .list-result-body ul li .row-item{ float: right;}
  .list-result-body ul li .row-item .col-item-3:first-child{padding-left: 10px; padding-right: 20px;}
  .list-result-body ul li .row-item .col-item-3{padding-left: 15px;}
  .list-result-body ul li .row-item .col-item-3:last-child{padding-right: 20px;}


  /*yuki*/
  .yuki-middle-top-img-bottom-box{width: 100px; height: 100px; position: absolute; left: 35%; bottom: 16%;}
  .yk-bet-input-number input{font-size: 1.1rem;font-weight: 900; padding: 6px 56px 6px 16px !important;}
  
  .yuki-res-head-item a{color: white !important; font-size: .86rem; font-weight: 600;}

  .yuki-res-body-col-item{width: 64px; margin-left: 6px; padding:3px 0px 3px 0px;}

  .yk-r-top-balance span{font-size: .80rem;}

  .yuki-left-top .yk-l-bottom{padding: 43px 0px 8px 0px !important;}

  .yuki-left-top .yk-l-top ul li:nth-child(1){font-size: .75rem;padding-top: 12px; padding-bottom: 8px;}
  .yuki-left-top .yk-l-top ul li:nth-child(2){font-size: .75rem; font-weight: 600;padding-bottom: 10px;}

  .yuki-right-top .yk-r-top ul li:nth-child(1){font-size: .75rem;padding-top: 6px; padding-bottom: 4px;}
  .yuki-right-top .yk-r-top ul li:nth-child(2){font-size: .75rem;padding-top: 1px; padding-bottom: 6px;}
  .yuki-right-top .yk-r-top ul li span {font-size: .75rem;padding-top: 10px; padding-bottom: 8px;}
 
}

@media screen and (max-width: 959px){
 
  .yuki-order-1{order: 2;}
  .yuki-order-2{order: 1;}
  .yk-bet-input-number input{font-size: 1.1rem;font-weight: 900; padding: 6px 56px 6px 16px !important;}

  .yuki-res-head-item a{color: white !important; font-size: .85rem; font-weight: 500;}

  .yuki-left-top .yk-l-bottom{padding: 53px 0px 8px 0px !important;}

  .yuki-middle-border-left{ width: 3px; height: 95%; background-color: #bf811c; position: absolute; top: 2%; left: 6px;}
  .yuki-middle-border-right{width: 3px; height: 95%; background-color: #bf811c; position: absolute; top: 2%; right: 6px;}
  .yuki-middle-border-bottom{width: auto; height: 3px; background-color: #bf811c; position: absolute; bottom: 2%;left: 6px;right: 6px;}
  /*.yuki-right-top .yk-r-bottom{padding: 3px 0px 6px 0px !important;}*/


  .yuki-left-top .yk-l-top ul li:nth-child(1){font-size: .85rem;padding-top: 12px; padding-bottom: 8px;}
  .yuki-left-top .yk-l-top ul li:nth-child(2){font-size: .85rem; font-weight: 600;padding-bottom: 10px;}

  .yuki-right-top .yk-r-top ul li:nth-child(1){font-size: .85rem;padding-top: 6px; padding-bottom: 4px;}
  .yuki-right-top .yk-r-top ul li:nth-child(2){font-size: .85rem;padding-top: 1px; padding-bottom: 6px;}
  .yuki-right-top .yk-r-top ul li span {font-size: .75rem;padding-top: 10px; padding-bottom: 8px;}
  
  .yk-r-bottom{
    padding: 40px 0px 40px 0px;
  }
  
}
@media screen and (max-width: 693px){
  .yuki-res-body-col-item{width: 60px; margin-left: 6px; padding:3px 0px 3px 0px;}
  .yuki-left-top .yk-l-top ul li:nth-child(1){font-size: .75rem;padding-top: 12px; padding-bottom: 8px;}
  .yuki-left-top .yk-l-top ul li:nth-child(2){font-size: .75rem; font-weight: 600;padding-bottom: 10px;}
  .yuki-left-top .yk-l-bottom{padding: 57px 0px 8px 0px !important;}

  
}

@media screen and (max-width: 670px){
  .yuki-res-head-item a{color: white !important; font-size: .75rem; font-weight: 600;}
  .yuki-res-body-col-item{width: 57px; margin-left: 3px; padding:3px 0px 3px 0px;}
  .yuki-left-top .yk-l-top ul li:nth-child(1){font-size: .75rem;padding-top: 12px; padding-bottom: 8px;}
  .yuki-left-top .yk-l-top ul li:nth-child(2){font-size: .75rem; font-weight: 600;padding-bottom: 10px;}
  .lotto-nav-head{  background-color: #0c478b;   height: 50px;  overflow: hidden ; margin-top:10px}
}



/* screen iphone  iPhone 6+ */
@media screen and (max-width: 600px){


  /*yuki*/
  /*TOP*/
    .yuki-left-top .yk-l-bottom{padding: 35px 0px 8px 0px !important;}
    .yuki-left-top .yk-l-top ul li:nth-child(1){font-size: .75rem;padding-top: 12px; padding-bottom: 8px;}
    .yuki-left-top .yk-l-top ul li:nth-child(2){font-size: .75rem; font-weight: 600;padding-bottom: 10px;}
    .yuki-padding-6{padding: 2px 2px 2px 2px !important;}
    .yuki-left-top{border: 2px solid #0c478b;}
    .yuki-left-top .yk-l-middle{padding: 4px 0px 4px 0px !important; margin: -16px 0px 0px 0px !important;}
    .yuki-left-top .yk-l-middle span{font-size: 1rem;}
    .yuki-left-top .yk-l-bottom span{font-size: 2.2rem; }

    .yuki-right-top .yk-r-top ul li:nth-child(1){font-size: .75rem;padding-top: 6px; padding-bottom: 4px;}
    .yuki-right-top .yk-r-top ul li:nth-child(2){font-size: .75rem;padding-top: 1px; padding-bottom: 2px;}

    /*
    .yuki-right-top .yk-r-bottom{padding: 0px 0px 6px 0px !important;}*/

    .yuki-right-top .yk-r-bet-limit{padding: 4px 0px 4px 0px !important;}
    .yuki-right-top .yk-r-bet-limit span{font-size: 1rem; font-weight: 600;}
    .yuki-right-top{border: 2px solid #0c478b;}
    .yuki-right-top .yk-r-top ul li{color: #1e1e1e; font-size: .75rem; font-weight: 600; padding-left: 4px;}
    .yuki-right-top .yk-r-top ul li span{color: rgb(187, 52, 52); font-weight: 600; font-size: 1rem;}

    .yuki-right-top .yk-r-middle{padding: 4px 0px 4px 0px !important; margin: -16px 0px 0px 0px !important;}
    .yuki-right-top .yk-r-middle span{ font-size: 1rem; font-weight: 600; }

    

    /*YEAR*/
    .yuki-odd-even-item{ font-size: 1rem; font-weight: 600;}

    .yuki-res-padding-6{padding: 2px 2px 2px 2px !important;}
    .yuki-middle-top-img-bottom-box{width: 100px; height: 100px; position: absolute; left: 30%; bottom: 12%;}
 
    .yuki-order-1{order: 2;}
    .yuki-order-2{order: 1;}
    .yk-bet-input-number input{font-size: .80rem;font-weight: 900; padding: 6px 56px 6px 10px !important;}
    .yk-bet-input-number input{font-size: .75rem;font-weight: 900; padding: 3px 46px 3px 3px !important;}
    .yk-bet-input-number span{ width: 46px; height: 49px;}
    .yk-bet-input-number span .v-icon.v-icon{line-height: 40px; font-size: 1.75rem; font-weight: 900; color: red;}
    .yk-bet-repet-btn-content span{font-size: 1rem; line-height: 50px; }
    .yk-bet-bet-btn-content span{font-size: 1rem; line-height: 50px;}

    .yuki-res-head-item a{color: white !important; font-size: .70rem; font-weight: 600;}

    .yuki-res-body-col-item{width: 48px; margin-left: 3px; padding:3px 0px 3px 0px;}

    .yk-r-bottom{
    padding: 13px 0px 20px 0px;
  }
  .yuki-right-top .yk-r-bottom span{font-size: 1rem; font-weight: 600;}
  .yuki-left-top .yk-l-bottom{padding: 25px 0px 8px 0px !important;}
}

@media screen and (max-width: 529px){
    .yuki-middle-top-img-bottom-box{width: 90px; height: 90px; position: absolute; left: 30%; bottom: 12%;}
    .yk-bet-input-number input{font-size: .75rem;font-weight: 900; padding: 3px 46px 3px 3px !important;}
    .yk-bet-input-number span{ width: 46px; height: 49px;}
    .yk-bet-input-number span .v-icon.v-icon{line-height: 40px; font-size: 1.75rem; font-weight: 900; color: red;}
    .yk-bet-repet-btn-content span{font-size: 1rem; line-height: 50px; }
    .yk-bet-bet-btn-content span{font-size: 1rem; line-height: 50px;}

    .yuki-res-body-col-item{width: 41px; margin-left: 2px; padding:3px 0px 3px 0px;}

    .yuki-right-top .yk-r-top ul li{color: #1e1e1e; font-size: .75rem; font-weight: 600; padding-left: 4px;}
    .yuki-right-top .yk-r-top ul li:nth-child(2) span{color: rgb(187, 52, 52); font-weight: 600; font-size: .75rem;}
    .yuki-right-top .yk-r-top ul li:nth-child(2){font-size: .75rem; font-weight: 600;padding-bottom: 10px;}

    .yuki-right-top .yk-r-bottom span{font-size: .70rem; font-weight: 600;}
    .yk-r-bottom{padding: 20px 0px 18px 0px;}
    .yuki-right-top .yk-r-top ul li{color: #1e1e1e; font-size: .75rem; font-weight: 600; padding-left: 4px;}
    .yuki-right-top .yk-r-top ul li span{color: rgb(187, 52, 52); font-weight: 600; font-size: .75rem;}

    
}

@media screen and (max-width: 450px){
    .yuki-middle-top-img-bottom-box{width: 90px; height: 90px; position: absolute; left: 30%; bottom: 12%;}
    .yk-bet-input-number input{font-size: .75rem;font-weight: 900; padding: 3px 46px 3px 3px !important;}
    .yk-bet-input-number span{ width: 46px; height: 49px;}
    .yk-bet-input-number span .v-icon.v-icon{line-height: 40px; font-size: 1.75rem; font-weight: 900; color: red;}
    .yk-bet-repet-btn-content span{font-size: 1rem; line-height: 50px; }
    .yk-bet-bet-btn-content span{font-size: 1rem; line-height: 50px;}

    .yuki-res-body-col-item{width: 41px; margin-left: 2px; padding:3px 0px 3px 0px;}
}

/* screen iphone  12/13 pro max */
@media screen and (max-width: 428px){
 
  /*yuki*/
  /*TOP*/
    .yuki-left-top .yk-l-top ul li:nth-child(1){font-size: .75rem;padding-top: 12px; padding-bottom: 8px;}
    .yuki-left-top .yk-l-top ul li:nth-child(2){font-size: .75rem; font-weight: 600;padding-bottom: 10px;}
    .yuki-padding-6{padding: 2px 2px 2px 2px !important;}
    .yuki-left-top{border: 2px solid #0c478b;}
    .yuki-left-top .yk-l-middle{padding: 4px 0px 4px 0px !important; margin: -16px 0px 0px 0px !important;}
    .yuki-left-top .yk-l-middle span{font-size: 1rem;}
    .yuki-left-top .yk-l-bottom span{font-size: 2.2rem; }

    .yuki-right-top .yk-r-bet-limit{padding: 4px 0px 4px 0px !important;}
    .yuki-right-top .yk-r-bet-limit span{font-size: 1rem; font-weight: 600;}
    .yuki-right-top{border: 2px solid #0c478b;}
    .yuki-right-top .yk-r-top ul li{color: #1e1e1e; font-size: .75rem; font-weight: 600; padding-left: 4px;}
    .yuki-right-top .yk-r-top ul li span{color: rgb(187, 52, 52); font-weight: 600; font-size: 1rem;}

    .yuki-right-top .yk-r-middle{padding: 4px 0px 4px 0px !important; margin: -16px 0px 0px 0px !important;}
    .yuki-right-top .yk-r-middle span{ font-size: 1rem; font-weight: 600; }
    .yuki-right-top .yk-r-bottom span{color: #333; font-size: 1.70rem; font-weight: 600;}

    /*YEAR*/
    .yuki-odd-even-item{ font-size: 1rem; font-weight: 600;}

    .yuki-middle-top-img-bottom-box{width: 90px; height: 90px; position: absolute; left: 24%; bottom: 12%;}

    .yk-bet-input-number input{font-size: .75rem;font-weight: 900; padding: 3px 46px 3px 3px !important;}
    .yk-bet-input-number span{ width: 46px; height: 49px;}
    .yk-bet-input-number span .v-icon.v-icon{line-height: 40px; font-size: 1.75rem; font-weight: 900; color: red;}
    .yk-bet-repet-btn-content span{font-size: 1rem; line-height: 50px; }
    .yk-bet-bet-btn-content span{font-size: 1rem; line-height: 50px;}
 
  .yuki-order-1{order: 2;}
  .yuki-order-2{order: 1;}
}

@media screen and (max-width: 414px){
    .yuki-middle-top-img-bottom-box{width: 90px; height: 90px; position: absolute; left: 26%; bottom: 12%;}
    .yk-bet-input-number input{font-size: .66rem;font-weight: 900; padding: 3px 46px 3px 3px !important;}
    .yk-bet-input-number span{ width: 46px; height: 49px;}
    .yk-bet-input-number span .v-icon.v-icon{line-height: 40px; font-size: 1.75rem; font-weight: 900; color: red;}
    .yk-bet-repet-btn-content span{font-size: 1rem; line-height: 50px; }
    .yk-bet-bet-btn-content span{font-size: 1rem; line-height: 50px;}

    .yuki-left-top .yk-l-bottom{padding: 26px 0px 6px 0px !important;}
    /*
    .yuki-right-top .yk-r-bottom{padding: 0px 0px 6px 0px !important; font-size: 1rem !important;}*/

    .yuki-left-top .yk-l-top ul li:nth-child(1){font-size: .65rem;padding-top: 12px; padding-bottom: 8px;}
    .yuki-left-top .yk-l-top ul li:nth-child(2){font-size: .65rem; font-weight: 600;padding-bottom: 10px;}
    /*
    .yuki-right-top .yk-r-top ul li{color: #1e1e1e; font-size: .70rem; font-weight: 600; padding-left: 4px;}
    .yuki-right-top .yk-r-top ul li span{color: rgb(187, 52, 52); font-weight: 600; font-size: .70rem;}*/

    /*.yk-r-bottom{padding: 21px 0px 22px 0px;}*/
  /*.yuki-right-top .yk-r-bottom span{font-size: 1rem; font-weight: 600;}*/

    .yuki-right-top .yk-r-bottom span{font-size: .70rem; font-weight: 600;}
    .yk-r-bottom{padding: 20px 0px 18px 0px;}
    .yuki-right-top .yk-r-top ul li{color: #1e1e1e; font-size: .65rem !important; font-weight: 600; padding-left: 4px;}
    .yuki-right-top .yk-r-top ul li span{color: rgb(187, 52, 52); font-weight: 600; font-size: .65rem !important;}

    .yuki-right-top .yk-r-bet-limit span{ font-size: .75rem;}
}



@media screen and (max-width: 390px){
  .yuki-left-top .yk-l-top ul li:nth-child(2){font-size: .60rem; font-weight: 600;padding-bottom: 10px;}
  .yuki-left-top .yk-l-middle{padding: 6px 0px 6px 0px !important; margin: -16px 0px 0px 0px !important;}

  .yuki-left-top .yk-l-bottom{padding: 20px 0px 8px 0px !important;}
  /*.yuki-right-top .yk-r-bottom{padding: 0px 0px 6px 0px !important;}*/
  .yk-r-bottom{padding: 19px 0px 20px 0px;}
  .yuki-right-top .yk-r-bottom span{font-size: .60rem; font-weight: 600;}
  .yuki-right-top .yk-r-top ul li{color: #1e1e1e; font-size: .60rem; font-weight: 600; padding-left: 4px;}
  .yuki-right-top .yk-r-top ul li span{color: rgb(187, 52, 52); font-weight: 600; font-size: .60rem;}
  
}

/* screen iphone  11 */
@media screen and (max-width: 375px){

  /*yuki*/

  /*TOP*/
    .yuki-left-top .yk-l-top ul li:nth-child(1){font-size: .66rem;padding-top: 12px; padding-bottom: 8px;}
    .yuki-left-top .yk-l-top ul li:nth-child(2){font-size: .66rem; font-weight: 600;padding-bottom: 10px;}

    .yuki-padding-6{padding: 2px 2px 2px 2px !important;}
    .yuki-left-top{border: 2px solid #0c478b;}
    .yuki-left-top .yk-l-middle{padding: 4px 0px 4px 0px !important; margin: -16px 0px 0px 0px !important;}
    .yuki-left-top .yk-l-middle span{font-size: 1rem;}
    
    

    .yuki-right-top .yk-r-bet-limit{padding: 4px 0px 4px 0px !important;}
    .yuki-right-top .yk-r-bet-limit span{font-size: 1rem; font-weight: 600;}
    .yuki-right-top{border: 2px solid #0c478b;}


    .yuki-right-top .yk-r-middle{padding: 4px 0px 4px 0px !important; margin: -16px 0px 0px 0px !important;}
    .yuki-right-top .yk-r-middle span{ font-size: 1rem; font-weight: 600; }
    .yuki-right-top .yk-r-bottom span{color: #333; font-size: 1.70rem; font-weight: 600;}

    /*YEAR*/
    .yuki-odd-even-item{ font-size: 1rem; font-weight: 600;}

    .yuki-middle-top-img-bottom-box{width: 90px; height: 90px; position: absolute; left: 24%; bottom: 12%;}
 
    .yuki-order-1{order: 2;}
    .yuki-order-2{order: 1;}

    .yk-bet-input-number input{font-size: .75rem;font-weight: 900; padding: 3px 46px 3px 3px !important;}
    .yk-bet-input-number span{ width: 46px; height: 49px;}
    .yk-bet-input-number span .v-icon.v-icon{line-height: 40px; font-size: 1.75rem; font-weight: 900; color: red;}
    .yk-bet-repet-btn-content span{font-size: 1rem; line-height: 50px; }
    .yk-bet-bet-btn-content span{font-size: 1rem; line-height: 50px;}

    /*.yuki-right-top .yk-r-bottom{padding: 0px 0px 6px 0px !important;}*/

    .yuki-left-top .yk-l-bottom{padding: 35px 0px 8px 0px !important;}
    .yuki-left-top .yk-l-bottom span{font-size: 2.2rem; }

    .yuki-right-top .yk-r-top ul li{color: #1e1e1e; font-size: .58rem !important; font-weight: 600; padding-left: 4px;}
    .yuki-right-top .yk-r-top ul li:nth-child(2) span{color: rgb(187, 52, 52); font-weight: 600; font-size: .58rem !important;}
    .yuki-right-top .yk-r-top ul li:nth-child(2){font-size: .65rem; font-weight: 600;padding-bottom: 10px;}

    .yuki-right-top .yk-r-bottom span{font-size: .70rem; font-weight: 600;}
    .yk-r-bottom{padding: 24px 0px 24px 0px;}

    /*
    .yuki-right-top .yk-r-bottom span{font-size: .70rem; font-weight: 600;}
    .yk-r-bottom{padding: 20px 0px 18px 0px;}
    .yuki-right-top .yk-r-top ul li{color: #1e1e1e; font-size: .65rem !important; font-weight: 600; padding-left: 4px;}
    .yuki-right-top .yk-r-top ul li span{color: rgb(187, 52, 52); font-weight: 600; font-size: .65rem !important;}*/

    .yuki-right-top .yk-r-bet-limit span{ font-size: .75rem;}
 
}


</style>
